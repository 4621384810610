<template>
 <div>
  <svg id="Laag_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientTransform="matrix(1 0 0 -1 0 184)" gradientUnits="userSpaceOnUse" id="icon-menu-lunch_00000090282111286118440640000007097442763199988354_" x1="152.8134" x2="39.5165" y1="143.9525" y2="48.8768">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <rect :fill="background" height="183" width="183"/>
   <path d="M56.5,78.8  H48V49.1h-8.5v29.7h-8.5V49.1h-8.5v29.7c0,8.9,7,16.3,15.9,16.8v38.3h10.6V95.6C58,95.1,65,87.7,65,78.8V49.1h-8.5V78.8z   M139.2,66.1V100h10.6v33.9h10.6V49.1C148.7,49.1,139.2,58.6,139.2,66.1L139.2,66.1z M120.3,74.4l-8.1,8.1c1.5,1.2,2.8,2.5,4,4  l8.1-8.1L120.3,74.4z M120.8,97.4c0.2,1.9,0.2,3.7,0,5.6h11.4v-5.6L120.8,97.4z M116.2,114c-1.2,1.5-2.5,2.8-4,4l8.1,8.1l4-4  L116.2,114z M95.7,122.5v11.4h5.6v-11.4C99.4,122.7,97.6,122.7,95.7,122.5z M80.8,114l-8.1,8l4,4l8.1-8.1  C83.3,116.8,81.9,115.5,80.8,114z M76.2,97.4H64.8v5.6h11.4C76,101.1,76,99.3,76.2,97.4z M76.7,74.4l-4,4l8.1,8.1  c1.2-1.5,2.5-2.8,4-4L76.7,74.4z M95.7,66.5v11.4c1.9-0.2,3.7-0.2,5.6,0V66.5L95.7,66.5z M98.5,83.3c-9.3,0-16.9,7.5-16.9,16.8  s7.5,16.9,16.9,16.9s16.8-7.5,16.8-16.9l0,0C115.3,90.9,107.8,83.4,98.5,83.3L98.5,83.3z M98.5,111.4c-6.2,0-11.2-5-11.2-11.2  c0-6.2,5-11.2,11.2-11.2c6.2,0,11.2,5,11.2,11.2c0,0,0,0,0,0C109.7,106.4,104.7,111.4,98.5,111.4L98.5,111.4z" id="icon-menu-lunch" style="fill:url(#icon-menu-lunch_00000090282111286118440640000007097442763199988354_);"/>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>